import axios from "axios"
let vlogin_id = localStorage.getItem('login_id');
class ApCatalogueService {


    list() {
        vlogin_id = localStorage.getItem('login_id');
        let form = {login_id:vlogin_id};
        return axios.post("ap_catalogue/list_catalogue", form);

    }
    
    view_catalogue(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_catalogue/view_catalogue", form);

    }

    insert_catalogue(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_catalogue/insert_catalogue", form);

    }

    update_catalogue(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_catalogue/update_catalogue", form);

    }    

    list_catalogue_item(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_catalogue/list_catalogue_item", form);

    }


    view_catalogue_item(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_catalogue/view_catalogue_item", form);

    }

    update_catalogue_item(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_catalogue/update_catalogue_item", form);

    }    

    save_catalogue_item_image(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_catalogue/save_catalogue_item_image", form);

    }    

    search_catalogue_item(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_catalogue/search_catalogue_item", form);

    }    

    insert_catalogue_item(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_catalogue/insert_catalogue_item", form);

    }
    
    get_ci_parameter(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_catalogue/get_ci_parameter", form);

    }
        

  
}

export default new ApCatalogueService();